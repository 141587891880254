import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../components/layout"
import { VieSportiveNav } from "../../components/menus/VieSportiveNav"

const Documents: React.FC = () => (
  <Layout>
    <Helmet title="Document sportifs" />
    <div className="container">
      <div className="row mt-3">
        <div className="secondary-col">
          <VieSportiveNav />
        </div>
        <div className="col-md-8 col-lg-9">
          <h1>Les documents</h1>
          <br />
          <h2>Sélections</h2>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Objet</th>
                <th scope="col">MàJ</th>
                <th scope="col">Lien</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Groupe Régional Jeunes</td>
                <td>voir fichier</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/sCYGS2Z88P77CnE" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>

            </tbody>
          </table>
          <h2>Cahiers des charges</h2>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Document</th>
                <th scope="col">Rév.</th>
                <th scope="col">Lien</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Championnats Régionaux - Salle</td>
                <td>Décembre 2023</td>
                <td>
                  <a href="/vie-sportive/documents/cc-CR-Salle2023-12.pdf" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Championnats Régionaux - TAEi</td>
                <td>Novembre 2022</td>
                <td>
                  <a href="/vie-sportive/documents/cc-CR-TAEI2022-11.pdf" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Championnats Régionaux - TAEn</td>
                <td>Novembre 2022</td>
                <td>
                  <a href="/vie-sportive/documents/cc-CR-TAEN2022-11.pdf" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Championnats Régionaux - Tirs de parcours</td>
                <td>Octobre 2022</td>
                <td>
                  <a href="/vie-sportive/documents/cc-CR-Parcours2022-10.pdf" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Division Régionale Excellence - Division Régionale Jeunes</td>
                <td>Mars 2024</td>
                <td>
                  <a href="/vie-sportive/documents/cc-DRE-DRJ2024-03.pdf" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              {/* <tr>
                <td>Cahier des Charges ETAF-R</td>
                <td>2019</td>
                <td>
                  <a href="/vie-sportive/documents/cahier-des-charges-ETAF-R-2019.pdf" target="_blank" rel="noopener noreferrer">
                    PDF
                  </a>
                </td>
              </tr>
              <tr>
                <td>Dossier de demande d'habilitation ETAF-R</td>
                <td>2019</td>
                <td>
                  <a
                    href="/vie-sportive/documents/dossier-de-demande-habilitation-ETAF-R-2019.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF
                  </a>
                </td>
              </tr> */}
            </tbody>
          </table>
          <br />
          <h2>Divers</h2>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Document</th>
                <th scope="col">Rév.</th>
                <th scope="col">Lien</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Notice fabrication potence "grand handicap" (fauteuil)</td>
                <td>2022</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/LnerSLf4NE53Bcw" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Tutoriel de mise en ligne des mandats sur ffta.fr</td>
                <td>2019</td>
                <td>
                  <a href="/vie-sportive/documents/tutoriel-mandats-ffta.pdf" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              {/* <tr>
                <td>
                  Championnats régionaux
                  <br />
                  Programmation des secteurs d&#39;affectation
                  <br />
                  Olympiade 2017 - 2021
                </td>
                <td>2018</td>
                <td>
                  <a href="/vie-sportive/documents/championnats-secteurs-2017-2021.pdf" target="_blank" rel="noopener noreferrer">
                    PDF
                  </a>
                </td>
              </tr>
              <tr>
                <td>Programmation / Organisation des Championnats régionaux</td>
                <td>2017</td>
                <td>
                  <a href="/vie-sportive/documents/championnats-programmation.pdf" target="_blank" rel="noopener noreferrer">
                    PDF
                  </a>
                </td>
              </tr>
              <tr>
                <td>Division Régionale Excellence</td>
                <td>2017</td>
                <td>
                  <a href="/vie-sportive/documents/DRE-2017.pdf" target="_blank" rel="noopener noreferrer">
                    PDF
                  </a>
                </td>
              </tr>
              <tr>
                <td>Transfert de licences entre clubs</td>
                <td>2017</td>
                <td>
                  <a href="/demarches/transfert-licences-2017.pdf" target="_blank" rel="noopener noreferrer">
                    PDF
                  </a>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
)

export default Documents
